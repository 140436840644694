import React, { useState } from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

// Styles
import styled from "styled-components"

export default function ClinicalCase(props) {
  const { title, desc, caseId } = props

  function handleClick() {
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "Case",
      // string - required - Type of interaction (e.g. 'play')
      action: "Click",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: caseId,
    })
    console.log("clicked case")
    window.open("https://rounds.medzcool.com/case/" + caseId, "_blank")
  }

  const [hover, setHover] = useState(false)
  function toggleHover() {
    setHover(!hover)
  }

  return (
    <ClinicalCaseContainer
      onClick={() => handleClick()}
      onMouseEnter={() => toggleHover()}
      onMouseLeave={() => toggleHover()}
      hover={hover}
    >
      <div>
        <TopLabel>Practice Case</TopLabel>
        <SmallTitle>{title}</SmallTitle>
        <BodyCopy className="desc">{desc}</BodyCopy>
        <BodyCopy className="answer">Answer Case</BodyCopy>
      </div>
    </ClinicalCaseContainer>
  )
}

const fontFamily = {
  sansSerif: "futura-pt, sans-serif",
}

const fontWeight = {
  bold: "700",
  regular: "400",
  light: "300",
}

const TopLabel = styled.div`
  font-weight: ${fontWeight.light};
  font-size: 14px;
  color: gray;
`

const BodyCopy = styled.div`
  font-size: 17px;
  font-family: ${fontFamily.sansSerif};
  font-weight: ${fontWeight.regular};
  margin-bottom: 5px;
  margin-top: 5px;
  &.desc {
    line-height: initial;
    color: gray;
    margin-top: 0;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
`

const SmallTitle = styled.div`
  font-size: 17px;
  font-weight: 700;
  font-family: ${fontFamily.sansSerif};
  line-height: normal;
  margin-bottom: 5px;
  color: #333333;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`

const ClinicalCaseContainer = styled.div`
  position: absolute;
  max-width: 215px;
  right: -275px;
  font-family: ${fontFamily.sansSerif};
  margin: auto;
  cursor: pointer;
  padding: 5px;
  background: ${props => (props.hover ? "#FAFAFA" : "none")};
  .answer {
    color: blue;
    font-size: 14px;
    margin-top: 10px;
    text-decoration: underline;
  }
  @media (max-width: 1000px) {
    position: relative;
    max-width: 100%;
    right: 0;
    margin-bottom: 1.75rem;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    padding: 25px 0;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`

import React from "react"
import { Link } from "gatsby"
import "./layout.css"
import alex from "./../../content/assets/alex.jpg"

import styled from "styled-components"

export default function AlexBio(props) {
  return (
    <AlexContainer>
      <img src={alex} alt="Alexander Tataryn" />
      <div>
        <div className="author">Alexander Tataryn</div>
        <p>
          As a Medical-Surgical/Telemetry Nurse, Alex enjoys every single moment of what he does - whether it's as simple as putting a warm blanket on a patient to lifesaving procedures like CPR, he takes pride in all of it. Based in Portland, Oregon, he enjoys hiking, weightlifting and video games.
        </p>
      </div>
    </AlexContainer>
  )
}

const AlexContainer = styled.div`
  font-family: futura-pt, sans-serif;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 15px;
  margin-top: 50px;
  padding-top: 50px;
  border-top: gray 1px solid;
  img {
    border-radius: 50%;
    max-width: 100px;
    justify-self: center;
  }
  .author {
    font-weight: 700;
    font-size: 18px;
  }
  p {
    font-family: adobe-garamond-pro, serif;
    font-size: 18px;
    line-height: 150%;
    margin-top: 0;
  }
`
